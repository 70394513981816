import {
    ApplicantInfoOverviewContainer,
    ApplicantInfoTitleBold,
    ApplicantOverviewContainer,
    ApplicantOverviewInfoContactAnchor,
    ApplicantOverviewInfoContactContainer,
} from "./style";
import type { Applicant } from "./types";

export const ApplicantInfoOverview = (props: {
    applicant: Applicant;
}) => {
    const { applicant } = props;
    const { firstName, lastName, nationalId, mobileNumber, emailAddress } = applicant;

    return (
        <ApplicantOverviewContainer>
            <ApplicantInfoOverviewContainer>
                <ApplicantInfoTitleBold>
                    {firstName} {lastName}
                </ApplicantInfoTitleBold>
                {nationalId}
            </ApplicantInfoOverviewContainer>
            <ApplicantOverviewInfoContactContainer>
                <ApplicantOverviewInfoContactAnchor href={`tel:${mobileNumber}`}>
                    {mobileNumber}
                </ApplicantOverviewInfoContactAnchor>
                <ApplicantOverviewInfoContactAnchor href={`mailto:${emailAddress}`}>
                    {emailAddress}
                </ApplicantOverviewInfoContactAnchor>
            </ApplicantOverviewInfoContactContainer>
        </ApplicantOverviewContainer>
    );
};
